<template>
  <v-container fluid class="mt-16 mb-3 pt-4">
    <v-card class="bgImg elevation-15">
      <v-row class="ma-1 ma-lg-12">
        <v-spacer />

        <v-col cols="12" xs="5" md="4" xl="5">
          <v-img
            max-width="22.5vh"
            max-height="22.5vh"
            class="mx-auto offset-img rounded-xl elevation-1 cursor-pointer"
            cover
            src="@/assets/illus/brittney-weng-unsplash.jpg"
            @click="$router.push('/product')"
          />
          <v-card class="mt-n14 pt-14 glasscard-primary neu-shadow" color="sand">
            <v-card-text class="text-center">
              <v-col>
                <v-skeleton-loader v-if="!productData.name" type="heading" color="transparent" />
                <h1 v-else class="monkey-title mb-4">{{ productData.name }}</h1>

                <v-skeleton-loader v-if="!productData.shortDesc" type="paragraph" color="transparent" />
                <p v-else class="" v-html="productData.shortDesc" />

                <v-btn
                  color="primary"
                  class="mt-4 px-6 mx-10"
                  to="/product"
                >En savoir plus</v-btn>
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>

  <v-carousel
    v-if="smAndDown"
    class="bg-accent text-center"
    height="180px"
    hide-delimiter-background
    delimiter-icon="fa fa-star"
  >
    <v-carousel-item
      v-for="feature in features"
      :key="feature"
      class="pt-7"
    >
      <v-icon
        :icon="feature.icon"
        color="sand"
        class="mb-2"
      />

      <h3 class="monkey-title">{{ feature.title.toUpperCase() }}</h3>
      <h4 class="font-weight-regular">{{ feature.subtitle }}</h4>
    </v-carousel-item>
  </v-carousel>
  <v-row v-else class="bg-accent text-center pa-2 pa-sm-10">
    <v-col
      v-for="feature in features"
      :key="feature"

      cols="12"
      sm=""
    >
      <v-icon
        :icon="feature.icon"
        color="sand"
        class="mb-2"
      />

      <h3 class="monkey-title">{{ feature.title.toUpperCase() }}</h3>
      <h4 class="font-weight-regular">{{ feature.subtitle }}</h4>
    </v-col>
  </v-row>
</template>

<script setup>
import { useDisplay } from "vuetify";

const { smAndDown } = useDisplay();
</script>

<script>
import axios from "axios";
import { useStoreStore } from "~/stores/store";

export default {
  name: "HomePage",
  data() {
    return {
      productData: {},
      errorProductLoading: false,

      features: [
        {
          title: "Livraison 24h offerte",
          subtitle: "Dès 60€ d'achat en France",
          icon: "fa fa-truck",
        },
        {
          title: "Made in France",
          subtitle: "Gage de qualité et de traçabilité",
          icon: "fa fa-wine-glass",
        },
        {
          title: "Paiement sécurisé",
          subtitle: "Visa, Mastercard, Paypal",
          icon: "fa fa-shield-heart",
        },
        {
          title: "Efficacité prouvée",
          subtitle: "Par un laboratoire indépendant",
          icon: "fa fa-vial-circle-check",
        },
      ],
    };
  },
  created() {
    const config = useRuntimeConfig();
    const storeStore = useStoreStore();

    const productid = "X9ZUtmksaPsMOAICtzY7";

    if (storeStore.products[0]) this.productData = storeStore.products[0];

    axios.get(`${config.public.apiBase}/getProduct?productId=${productid}`, {
      headers: {
        Authorization: `Bearer ${config.public.apiToken}`,
      },
    }).then((response) => {
      this.productData = response.data.product;

      storeStore.updateProducts([this.productData]);
    }).catch(() => {
      this.errorProductLoading = true;
    });
  },
};
</script>

<style>
.bgImg {
  height: 80vh;

  background-image: url("@/assets/illus/kaeme-unsplash.jpg")!important;
  background-position: center!important;
  background-size: cover!important;
}
.offset-img {
  margin-top: 10px;
  z-index: 10!important;
}
</style>
